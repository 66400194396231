// scroll class add
jQuery(window).on("load scroll resize", function () {
  var st = jQuery(window).scrollTop();
  var wh = jQuery(window).height();
  var ww = jQuery(window).width();

  // fast(<1) slow(>1)
  jQuery(".fade, .fadeMask, .fadeUp, .fadeIn, fadeAnimation").each(function (
    i
  ) {
    var tg = jQuery(this).offset().top - wh * 0.9;
    if (st > tg) {
      jQuery(this).addClass("active");
    }
  });

  jQuery(".fade-up-fast").each(function (i) {
    var tg = jQuery(this).offset().top - wh * 0.8;
    if (st > tg) {
      jQuery(this).addClass("active");
    }
  });
}); // scroll class add END


// jQuery start
jQuery(function ($) {
  // SP 100vh(スクロールバー対策)
  // $('.loadingLogo').css('height',$(window).height());

  // Smooth scroll
  // ページ内リンク
  var width = $(window).width();
  if (width <= 1023) {
    var headerHight = 60; //ヘッダの高さ
  } else {
    var headerHight = 100; //ヘッダの高さ
  }

  $('a[href^="#"]').click(function () {
    var speed = 800;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top - headerHight;
    $("html, body").animate(
      {
        scrollTop: position,
      },
      speed,
      "swing"
    );
    return false;
  });

  // ページ外リンク
  $(window).on("load", function () {
    if (document.URL.match("#")) {
      var str = location.href;
      var cut_str = "#";
      var index = str.indexOf(cut_str);
      var href = str.slice(index);
      var target = href;
      var position = $(target).offset().top - headerHight;
      $("html, body").scrollTop(position);
      return false;
    }
  });

  // loading Fast
  $(document).ready(function () {
    jQuery(".loading").addClass("active");
  });

  // loading Slow
  // $(window).on('load', function () {
  //   jQuery(".loading").addClass("active");
  // });

  // Click event humberger menu
  jQuery(".navBar").click(function () {
    jQuery(".l-header").toggleClass("active");
    jQuery(".header__inner").toggleClass("active");
    jQuery(".navBar__line").toggleClass("active");
    jQuery(".header__body").toggleClass("active");
  });

  // Procuct List Toggle
  jQuery(".product__ttl").click(function () {
    jQuery(this).delay(5000).toggleClass("open");
    jQuery(this).next(".product__answer").slideToggle();
  });
  jQuery(".stockList__shop").click(function () {
    jQuery(this).delay(5000).toggleClass("open");
    jQuery(this).next(".stockList__info").slideToggle();
  });
});
// jQuery End

// // scroll = class add
// window.onscroll = function () {
//   // go to top btn
//   var page_top = document.getElementById('pageTop');
//   if (window.pageYOffset > 800) {
//     page_top.classList.add("active");
//   } else {
//     page_top.classList.remove("active");
//   }
// }

//ハンバーガーボタン
(function () {
  $("#js__buttonHamburger").click(function () {
    $("body").toggleClass("is-drawerActive");

    if ($(this).attr("aria-expanded") == "false") {
      $(this).attr("aria-expanded", true);
    } else {
      $(this).attr("aria-expanded", false);
    }
  });
})();

//アコーディオン
(function () {
  $(".js__accordion").click(function () {
    $(this).next("ul").slideToggle();
    if ($(this).attr("aria-expanded") == "false") {
      $(this).attr("aria-expanded", true);
    } else {
      $(this).attr("aria-expanded", false);
    }
  });
})();

//スライダー
$(".js__slider").slick({
  autoplay: true, // 自動再生
  autoplaySpeed: 4000, // 再生速度（ミリ秒設定） 1000ミリ秒=1秒
  arrows: false, // 矢印
});

//ヘッダー
var beforePos = 0; //スクロールの値の比較用の設定

//スクロール途中でヘッダーが消え、上にスクロールすると復活する設定を関数にまとめる
function ScrollAnime() {
  var elemTop = $("#js__headerArea").offset().top; //#js-headerAreaの位置まできたら
  var scroll = $(window).scrollTop();
  var body = document.getElementsByTagName(body);
  //ヘッダーの出し入れをする
  if (scroll == beforePos) {
    //IE11対策で処理を入れない
  } else if (elemTop > scroll || 0 > scroll - beforePos) {
    //ヘッダーが上から出現する
    $("#js__logo, #js__buttonHamburger").removeClass("UpMove"); //#headerにUpMoveというクラス名を除き
    $("#js__logo, #js__buttonHamburger").addClass("DownMove"); //#headerにDownMoveのクラス名を追加
  } else {
    //ヘッダーが上に消える
    $("#js__logo, #js__buttonHamburger").removeClass("DownMove"); //#headerにDownMoveというクラス名を除き
    $("#js__logo, #js__buttonHamburger").addClass("UpMove"); //#headerにUpMoveのクラス名を追加
  }

  if ($("body").hasClass("is-drawerActive") == true) {
    $("#js__logo, #js__buttonHamburger").removeClass("DownMove");
    $("#js__logo, #js__buttonHamburger").removeClass("UpMove");
  }
  beforePos = scroll; //現在のスクロール値を比較用のbeforePosに格納
}

// 画面をスクロールをしたら動かしたい場合の記述
if ($("body").hasClass("is-drawerActive") == false) {
  $(window).scroll(function () {
    ScrollAnime(); //スクロール途中でヘッダーが消え、上にスクロールすると復活する関数を呼ぶ
  });
}

//ページ内リンク対応ドロワーメニュー
$('a[href*="#"]').on("click", function () {
  $("body").removeClass("is-drawerActive");
  $("#js__buttonHamburger, .header__accordionButton").attr(
    "aria-expanded",
    false
  );
  $(".header__listSecondary").hide();
});
